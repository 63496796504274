body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #f1f2f3;
  color:#0A090C;
  font-family: 'Raleway', sans-serif;
}

.greeting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: url('./assets/Valley.svg');
  box-shadow: 0 4px 2px -2px gray;
}

.greeting-container {
  text-align: left;
}

.greeting-name, .greeting-intro {
  color:#04F06A;
  font-weight: 500px;
  font-size: 2rem;
  margin-bottom: 10px;
}

.greeting-intro {
  color:#f1f2f3;
}

.greeting-detail {
  font-weight: Bold;
  font-size: 4rem;
  color: #f1f2f3;
  margin-top: 0;
  margin-bottom:15px;
}

.greeting-story {
  margin-top:0;
  font-weight: 400px;
  font-size:1.5rem;
}

a {
  text-decoration: none;
  color:inherit;
}

.navbar {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  font-weight: 600;
  padding:1rem;
}

.nav-logo {
  margin-left: 2rem;
  font-size: 2rem;
  font-family: 'Leckerli One', cursive;
}

.nav-items {
  display: flex;
  list-style: none;
  padding:0;
  margin:0;
  text-align: left;
}

.nav-number {
  color:#058C42;
}


.nav-item {
  margin-left: 2rem;
}

.navItem-underline {
  display: inline-block;
  position: relative;
}

.navItem-underline:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -8px;
  left:0;
  background-color: #0D00A4;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}

.navItem-underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.nav-toggle {
  display: none;
}

.button {
  border-radius: 5px;
  border: 1px solid #f1f2f3;
  color: #f1f2f3;
  font-weight: bold;
  height: 60px;
  width: 125px;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  transition: color, 0.5s;
}

.button:hover {
  background-color:#0D00A4;
  color:#f1f2f3;
  border-color:#0D00A4;
  transition-timing-function: ease-in;
  cursor: pointer;
}

.blur {
  filter: blur(4px);
}

/*
Bodies
*/

.Contact {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.About {
  background: #f1f2f3;
  min-height: 100vh;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  align-items: center;
  padding:1rem;
}

.Work {
  min-height: 100vh;
}

.section-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:50%
}

.about-title {
  color:#0a090c;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.line {
  background-color:#058C42;
  height: 3px;
  width:7%;
  margin-top: 0px;
}

.about-story {
  color: #0a090c;
  padding: 1rem;
  width: 50%;
  text-align: left;
  line-height: 2rem;
  margin-top: 0.5rem;
  font-weight: 500;
  position: relative;
}

.story {
  margin-left: 0.7rem;
}

.about-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 50em) {
  /*Navbar on mobile */
  .nav-items {
    position: fixed;
    z-index: 1000;
    inset: 0 0 0 30%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background:#1A936F;
    padding: min(30vh, 10rem);
    gap: 2em;
    transform: translateX(100%);
  }

  .nav-item {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    color:#f1f2f3;
  }

  .nav-number {
    color:white;
  }

  .nav-items[data-visible='true'] {
    transform: translateX(0%);
    transition: transform 350ms ease-out;
  }

  .nav-toggle {
    display: block;
    position: absolute;
    z-index: 9999;
    background: url('./assets/hamburger.png');
    background-repeat: no-repeat;
    width: 2rem;
    border:0;
    aspect-ratio: 1;
    top: 3.5rem;
    right: 2rem;
  }  

  .nav-toggle[aria-expanded='true'] {
    background: url('./assets/close.png');
    background-repeat: no-repeat;
  }
  
  /*Welcome on mobile */
  .greeting-container {
    padding: 2rem;
  }

  .greeting-name, .greeting-intro {
    font-size: 1.5rem;
  }

  .greeting-detail {
    font-size: 2.5em;
  }
}


.facts {
  position: relative;
  height: 200px;
  width: 300px;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.facts-container {
  display:flex;
  justify-content: center;
  align-items: center;
  gap: 6;
  padding: 1rem;
}

/*this is the style for the colored corners */
.top, .bottom {
  position: absolute;
  width: 50px;
  height: 50px;
  pointer-events: none;
}

.top {
  top: 0;
  border-top: 2px solid #0D00A4;;
  border-top-left-radius: 5px;
}

.bottom {
  bottom: 0;
  border-bottom: 2px solid #058C42;
  border-bottom-right-radius: 5px;
}

.left {
  left: 0;
  border-left: 2px solid #0D00A4;
}

.right {
  right: 0;
  border-right: 2px solid #058C42;
}
/*end of style for colored corners */

.facts-content {
  margin: 50px, auto;
}

.fact-text {
  font-weight: 500;
}

.fact-title {
  margin-top:1rem;
}

/* About page on mobile */
@media (max-width: 60em) {
  .about-title {
    font-size: 2rem;
  }

  .about {
    padding: 1.8rem;
  }

  .about-story {
    width: 100%;
    line-height: 1.5rem;
  }

  .facts-container {
    flex-direction: column;
  }

  .fact-title {
    margin-top:1.3rem;
  }
}

.work-container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.projects {
  display:flex;
  justify-content: space-between;
  gap:5rem;
  padding-bottom: 2rem;
}

.project1 {
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.project-title {
  font-size:2.5rem;
  margin-bottom:0;
}

.project-description {
  font-size: 1.5rem;
  margin-top:1rem;
}

.project-image {
  height:500px;
  width: 900px;
  border-radius: 5px;
}

.project-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  position: relative;
}

.project-links {
  margin-top: 1rem;
  font-weight:600px;
}

.demo-link:hover {
  color:#0D00A4;
}

.github-link:hover {
  color:#058C42;
}


@media (max-width:90em) {
  .projects {
    flex-direction: column;
    align-items: center;
    gap:3rem;
  }

  .demo-link {
    display:none;
  }

  .project-description {
    font-size: 1rem;
  }

  .project-title {
    font-size: 1.5rem;
  }

  .project-image {
      width: 100%;
      height:auto;
  }

  .viz {
    order: -1;
  }

  .project-info {
    width: 70%;
    padding:1rem;
  }
}

/*CONTACT*/
.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:2rem;
}

.contact-content {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.contact-text {
  margin-bottom: 0.5rem;
  padding:1rem;
}

.cta {
  background-color: #0D00A4;
  transition: background-color, 0.5s;
}

.cta:hover {
  color: white;
}

.footer-container {
  background-color:#0a090c;
  color:white;
  padding:2rem;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-text {
  margin-top:1rem;
  font-size: 0.9rem;
}

.footer-text:hover {
  color:#04F06A;
}

.arrow {
  margin-top:3rem;
  display: flex;
  justify-content: center;
  background: url('assets/upwardarrow.png');
  height: 50px;
  width: 50px;
}

.arrow:hover {
  background: url('assets/upwardarrowhover.png')
}

.socials {
  display:flex;
  justify-content: center;
  gap: 1rem;
}

.github-container, .linkedin-container {
  height: 40px;
  width: 40px;
  background-color:white;
  display:flex;
  justify-content: center;
  align-items: center;
}

.github-container:hover, .linkedin-container:hover {
  -webkit-transform: translateY(-2px);
	transform: translateY(-2px);
  transition: transform 0.15s ease-in-out;
}